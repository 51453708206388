import styled from 'styled-components';
import colors from "../../assets/styles/variables/colors";

interface propType {
    customDisc?: boolean,
    disc?: boolean,
    circle?: boolean,
    square?: boolean,
    none?: boolean,
}

const listStyle: any = (value: propType) => {
    if (value.none) return `padding:0; list-style-type: none;`;

    if (value.customDisc) {
        return `
        list-style-type: none;
        padding:0; 
        li{
            position:relative;
        }
    `;
    }

    return `list-style-type: disc`;
};

export default styled.ul<propType>`
  ${(prop) => listStyle(prop)};

  a {
    color: ${colors.white};
    text-decoration: none;
  }
`

