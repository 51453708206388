// React requires
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {setConfiguration} from 'react-grid-system';
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

// React components
import App from './App';

// React custom configs
import './app/config/translate';

// React assets
import './assets/styles/scss/app.scss';

setConfiguration({
    defaultScreenClass: 'sm',
    gridColumns: 12,
    breakpoints: [540, 768, 1024, 1360],
    containerWidths: [540, 768, 1024, 1360]
});

const root = document.getElementById('root');

if (root.hasChildNodes()) {
    ReactDOM.hydrate(<App/>, root);
} else {
    ReactDOM.render(<App/>, root);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
