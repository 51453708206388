import styled from "styled-components";
import colors from "../../../assets/styles/variables/colors";
import typography from "../../../assets/styles/variables/typography";
import { device } from "../../../assets/styles/variables/breakpoints";
import { space } from "../../../assets/styles/variables/spaces";

type propType = {
  variant?: any;
};
export default styled.header`
  position: absolute;
  padding-bottom: 36px;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: ${(props: propType) =>
    props.variant.background && "rgba(255, 255, 255, 0.7)"};

  &:after {
    content: "";
    background-color: ${colors.secondary};
    width: 94%;
    height: 3px;
    position: relative;
    top: ${space(5)};
    display: block;
    margin: 0 auto;

    @media ${device.md} {
      background-color: rgba(255, 255, 255, 1);
      height: 1px;
    }
  }

  @media ${device.md} {
    //padding-top:45px;
  }

  @media ${device.sm} {
    //padding-top:15px;
  }

  ul {
    padding: 0;
    margin: 30px 0 0 0;
    text-align: right;

    li {
      list-style: none;
      display: inline-block;
      font-size: 1.1em;
      font-weight: bold;
    }
  }

  a {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: rgba(0, 0, 0, 0.1) 0px 3px 3px;
    color: ${(props: propType) =>
      props.variant.theme === "light" ? "rgb(255, 255, 255);" : colors.primary};
    text-decoration: none;
    padding: 15px 58px;
    &:last-child {
      padding-right: 0;
    }

    &.is-active {
      color: ${colors.secondary};

      &:hover {
        opacity: 1;
      }
    }
    &:hover {
      opacity: 0.7;
    }
  }

  @media ${device.lg} {
    a {
      font-size: 1em;
      padding: 0px 20px;
    }
  }

  @media ${device.md} {
    ul {
      max-width: 350px;
      text-align: left;
      padding: 15px;
      margin-top: 60px;

      @media ${device.xs} {
        margin-top: 45px;
      }
      &:before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 1px;
        background: ${colors.secondary};
        clear: both;
      }

      li {
        display: block;
        margin: 20px 0 10px 10px;
        &:first-child {
          margin-top: 30px;
        }
      }

      a {
        ${typography.navigation.header};
        color: ${(props: propType) =>
          props.variant.theme === "light"
            ? colors.header.navigation
            : colors.header.navigationDark};

        text-decoration: none;
        padding-right: 88px;
        position: relative;
        color: ${colors.primary};

        &:last-child {
          padding-right: 0;
        }

        @media ${device.lg} {
          //padding-right: 36px;
          ${typography.navigation.header.tablet};
        }
      }
    }
  }
`;
