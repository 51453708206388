// React requires
import {lazy} from 'react';

// React types
import {RouteObject} from "../app/types/route";

// React const
import {
    ABOUT_PAGE,
    CAREER_PAGE,
    CONTACT_PAGE,
    HOME_PAGE,
    NOT_FOUND_PAGE,
    INDUSTRIES_PAGE,
    CAREER_DETAIL_PAGE
} from "../app/consts/pages";

// React components
const Home = lazy(() => import('../views/Home'));
const About = lazy(() => import('../views/About'));
const Career = lazy(() => import('../views/Career'));
const CareerDetail = lazy(() => import('../views/Career/Subpage/CareerDetail'));
const Contact = lazy(() => import('../views/Contact'));
const Industries = lazy(() => import('../views/Industries'));
const NotFound = lazy(() => import('../views/NotFound'));

const list: Array<RouteObject> = [
    {
        name: HOME_PAGE,
        path: "/",
        exact: true,
        component: Home,
        show: true,
        children: [],
    },
    {
        name: ABOUT_PAGE,
        path: "/about",
        exact: false,
        component: About,
        show: true,
        children: [],
    },
    {
        name: INDUSTRIES_PAGE,
        path: "/industries",
        exact: false,
        component: Industries,
        show: true,
        children: [],
    },
    {
        name: CAREER_PAGE,
        path: "/career",
        exact: true,
        component: Career,
        show: true,
        children: [],
    },
    {
        name: CAREER_DETAIL_PAGE,
        path: "/career/:id",
        exact: true,
        component: CareerDetail,
        show: false,
        children: []
    },
    {
        name: CONTACT_PAGE,
        path: "/contact",
        exact: false,
        component: Contact,
        show: true,
        children: [],
    },
    {
        name: NOT_FOUND_PAGE,
        path: "/404.html",
        exact: false,
        component: NotFound,
        show: false,
        children: [],
    },
];

export default list;