// React requires
import React, {useState, useEffect, useRef} from 'react';

// React libraries
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';
import {RouteComponentProps, withRouter} from "react-router";
import i18next from 'i18next';

// React custom configs
import {MetaRoute} from "../../app/types/route";
import metaData from '../../data/meta';
import routes from '../../routes';

type PathParamsType = {
    param1: string,
}

type Props = RouteComponentProps<PathParamsType> & {
    location: string,
    children: any,
}


const Meta = (props: Props) => {
    const getRoute = () => {
        const data = routes.find(item => item.path === props.location.pathname);

        return data ? data : {name: '', title: '', description: ''};
    };


    const {t} = useTranslation();
    const [meta, setMeta] = useState(metaData[getRoute().name]);
    const location = useRef(props.location);

    useEffect(() => {
        if (props.location !== location.current) {
            setMeta(metaData[getRoute().name])
        }
    }, [props]);

    const helmet = (data: MetaRoute) => {
        let item = data !== undefined ? data : {name: '', title: '', description: '', image: ''};

        return (<Helmet>
            <title>{t(item.title)}</title>
            <meta charSet="utf-8"/>
            <meta name="theme-color" content="#ffffff"/>
            <meta name="description" content={t(item.description)}/>
            <meta name="title" content={t(item.title)}/>
            <meta name="site_name" content={process.env.SITE_NAME}/>

            <meta property="og:title" content={t(item.title)}/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content={process.env.APP_URL}/>
            <meta property="og:site_name" content={process.env.SITE_NAME}/>
            <meta property="og:image" content={item.image}/>
            <meta property="og:description" content={t(item.description)}/>
            <meta property="og:locale" content={i18next.language}/>

            <meta name="robots" content="index, follow"/>
            <link rel="canonical" href={process.env.APP_URL}/>
        </Helmet>)
    };


    return (
        <>
            {helmet(meta)}
            {props.children}
        </>
    )
};

export default withRouter(Meta);
