export default {
    translations: {
        meta: {
            home: {
                name: 'Home',
                title: 'NBM',
                description: 'Ontario’s trusted service provider of premier integrated Engineering solutions for over 30 years.',
                keywords: 'Keywords',
            },
            about: {
                name: 'About us',
                title: 'About - NBM',
                description: 'Engineering design, project management, coordination, inspection, testing, and surveying. Clients rely on our reputation for quality and dedication to facilitate projects of any scale.',
                keywords: 'Keywords',
            },
            industries: {
                name: 'Industries',
                title: 'Industries - NBM',
                description: 'NBM’s foundation is rooted in a mission to fulfill a public and industry need for integrated and reliable engineering services.',
                keywords: 'Keywords',
            },
            career: {
                name: 'Careers',
                title: 'Careers - NBM',
                description: 'With NBM’s focus on innovation, technology, & growth, our employees are exposed to multiple disciplines, the ability to learn quickly, & ample opportunity to grow within the company.',
                keywords: 'Keywords',
            },
            contact: {
                name: 'Contact',
                title: 'Contact - NBM',
                description: 'NBM Engineering office locations in Oakville, Pickering, and Cambridge. ',
                keywords: 'Keywords',
            },
            not_found: {
                name: 'Not Found',
                title: 'Not Found - NBM',
                description: 'Page not found, check is link valid.',
                keywords: 'Keywords',
            },
        },
        globals: {},
        layout: {
            header: {},
            footer: {
                title: {
                    group_1: 'About us',
                    group_2: 'Contact',
                    group_3: 'Our office',
                    group_4: 'Careers',
                },
                content: {
                    group_1: `Ontario's trusted service provider of premier integrated Engineering solutions for over 30 years.`,
                    group_2: `Find us on LinkedIn`,
                    group_3: ` <li>
                                <p>1525 Cornwall Rd. Unit 27
                                    Oakville, ON, L6H 7P2</p>
                            </li>
                           `,

                    group_4: `With NBM’s focus on innovation, technology, & growth, our employees are exposed to
                     multiple disciplines, the ability to learn quickly, & ample opportunity to grow within the company.`,
                },
                buttons: {
                    apply_now: 'Apply now'
                },
            }
        },
        pages: {},


    }
};

// <li>
//                                 <h4>PICKERING</h4>
//                                 <p>1465 Pickering Pkwy. Unit 101
//                                     Pickering, ON, L1V 1G7
//                                 </p>
//                             </li>
//                             <li>
//                                 <h4>CAMBRIDGE</h4>
//                                 <p>336 Eagle St. N. Unit 1B
//                                     Cambridge, ON, N3H 1C2</p>
//                             </li>