// React const
const HOME_PAGE: string = 'home';
const ABOUT_PAGE: string = 'about';
const CAREER_PAGE: string = 'careers';
const CAREER_DETAIL_PAGE: string = 'careers-detail';
const INDUSTRIES_PAGE: string = 'industries';
const CONTACT_PAGE: string = 'contact';
const NOT_FOUND_PAGE: string = '404';

export {
    HOME_PAGE,
    ABOUT_PAGE,
    CAREER_PAGE,
    CAREER_DETAIL_PAGE,
    INDUSTRIES_PAGE,
    CONTACT_PAGE,
    NOT_FOUND_PAGE
}