// React requires
import React, {FC} from "react";
import styled from "styled-components";

// React assets
import colors from "../../assets/styles/variables/colors";

const Loader: FC = () => {
    return (
        <SCLoader>
            <div className="loading">
                <div className="loading-text">
                    <span className="loading-text-words">L</span>
                    <span className="loading-text-words">O</span>
                    <span className="loading-text-words">A</span>
                    <span className="loading-text-words">D</span>
                    <span className="loading-text-words">I</span>
                    <span className="loading-text-words">N</span>
                    <span className="loading-text-words">G</span>
                </div>
            </div>
        </SCLoader>
    );
};

const SCLoader = styled.div`
  background: ${colors.primary};
  color: ${colors.white};
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.primary};
    z-index: 99999;
  }
  
  .loading-text{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    width: 100%;
    height: 100px;
    line-height: 100px;
    
    span{
        display: inline-block;
        margin: 0 5px;
        -webkit-filter: blur(0px);
        filter: blur(0px);

        &:nth-child(1){
            -webkit-animation: blur-text 1.5s 0s infinite linear alternate;
            animation: blur-text 1.5s 0s infinite linear alternate;
        }
        &:nth-child(2){
            -webkit-animation: blur-text 1.5s 0.2s infinite linear alternate;
            animation: blur-text 1.5s 0.2s infinite linear alternate;
        }
        &:nth-child(3){
           -webkit-animation: blur-text 1.5s 0.4s infinite linear alternate;
            animation: blur-text 1.5s 0.4s infinite linear alternate;
        }
        &:nth-child(4){
            -webkit-animation: blur-text 1.5s 0.6s infinite linear alternate;
            animation: blur-text 1.5s 0.6s infinite linear alternate;
        }
        &:nth-child(5){
            -webkit-animation: blur-text 1.5s 0.8s infinite linear alternate;
            animation: blur-text 1.5s 0.8s infinite linear alternate;
        }
        &:nth-child(6){
            -webkit-animation: blur-text 1.5s 1s infinite linear alternate;
            animation: blur-text 1.5s 1s infinite linear alternate;
        }
        &:nth-child(7){
            -webkit-animation: blur-text 1.5s 1.2s infinite linear alternate;
            animation: blur-text 1.5s 1.2s infinite linear alternate;
        }
    }
  }
`;

export default Loader;
