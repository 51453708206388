// React requires
import React, {useRef} from "react";

// React libraries
import {Link, NavLink} from "react-router-dom";
import {Col, Visible} from "react-grid-system";
import {motion, useCycle} from "framer-motion";
import classNames from "classnames";

// React custom configs
import routes from "../../routes";

// React assets
import Logo from "../UI/Logo";
import {useDimensions} from "../../app/hooks/useDimensions";

type Props = {
    isWhiteBg: boolean;
};
const Links = ({toggle}: any) => {
    const myRoutes = routes.filter((item) => item.show);

    const variants = {
        open: {
            transition: {staggerChildren: 0.07, delayChildren: 0.2},
            opacity: 1,
        },
        closed: {
            transition: {staggerChildren: 0.05, staggerDirection: -1},
            opacity: 0,
        },
    };

    return (
        <motion.ul variants={variants}>
            {myRoutes.map((route, index) => (
                <MenuItem key={index} route={route} toggle={toggle}/>
            ))}
        </motion.ul>
    );
};

const MenuItem = (props: any) => {
    const variants = {
        open: {
            y: 0,
            opacity: 1,
            transition: {
                y: {stiffness: 1000, velocity: -100},
            },
        },
        closed: {
            y: 50,
            opacity: 0,
            transition: {
                y: {stiffness: 1000},
            },
        },
    };

    return (
        <motion.li variants={variants}>
            <NavLink
                exact
                to={props.route.path}
                onClick={props.toggle}
                activeClassName="is-active"
            >
                {props.route.name}
            </NavLink>
        </motion.li>
    );
};

const LinkLogo = (props: Props) => {
    return (
        <Link to="/">
            <Logo variant={props.isWhiteBg && "light"} width={150}/>
        </Link>
    );
};
const Path = (props: any) => {
    return (
        <motion.path
            fill="transparent"
            strokeWidth="3"
            stroke="hsl(0, 0%, 18%)"
            strokeLinecap="round"
            {...props}
        />
    );
};

const MenuToggle = ({toggle}: any) => {
    return (
        <button className="btn-nav" aria-label="Toggle navigation" onClick={toggle}>
            <svg width="23" height="23" viewBox="0 0 23 23">
                <Path
                    variants={{
                        closed: {d: "M 2 2.5 L 20 2.5"},
                        open: {d: "M 3 16.5 L 17 2.5"},
                    }}
                />
                <Path
                    d="M 2 9.423 L 20 9.423"
                    variants={{
                        closed: {opacity: 1},
                        open: {opacity: 0},
                    }}
                    transition={{duration: 0.1}}
                />
                <Path
                    variants={{
                        closed: {d: "M 2 16.346 L 20 16.346"},
                        open: {d: "M 3 2.5 L 17 16.346"},
                    }}
                />
            </svg>
        </button>
    );
};

const Navigation = (props: Props) => {
    const [isOpen, toggleOpen] = useCycle(false, true);
    const containerRef = useRef(null);
    const {height} = useDimensions(containerRef);

    const isOpenToggle = () => {
        toggleOpen();
        if (isOpen) {
            document.body.classList.remove('ohidden');
        } else {
            document.body.classList.add('ohidden');
        }
    }

    return (
        <>
            <Visible xl lg md>
                <Col lg={2} md={3}>
                    <LinkLogo isWhiteBg={props.isWhiteBg}/>
                </Col>
                <Col lg={10} md={9}>
                    <Links/>
                </Col>
            </Visible>
            <Visible sm xs>
                <Col sm={4} xs={4}>
                    <LinkLogo isWhiteBg={props.isWhiteBg}/>
                </Col>
                <Col sm={8} xs={8}>
                    <motion.div
                        className={classNames({motion: true})}
                        initial={false}
                        animate={isOpen ? "open" : "closed"}
                        custom={height}
                        ref={containerRef}
                    >
                        <motion.div
                            initial={{opacity: 0, display: "none"}}
                            animate={{
                                opacity: isOpen ? 1 : 0,
                                display: isOpen ? "block" : "none",
                            }}
                            transition={{duration: 1}}
                            className={classNames({"motion-backdrop": true})}
                        />

                        <motion.div
                            className="background"
                            initial={{x: 0}}
                            animate={{x: isOpen ? "-500px" : 0}}
                            transition={{duration: 1}}
                        >
                            <Links toggle={() => isOpenToggle()}/>
                        </motion.div>
                        <MenuToggle toggle={() => isOpenToggle()}/>
                    </motion.div>
                </Col>
            </Visible>
        </>
    );
};

export default Navigation;
