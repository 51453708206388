// React requires
import React, { FC } from "react";

// React libraries
import { Container, Row, Col } from "react-grid-system";
import { useTranslation } from "react-i18next";

// React custom configs
import contactData from "../../../data/contact";
import routes from "../../../routes";
import { CAREER_PAGE } from "../../../app/consts/pages";

// React components
import Icon from "../../UI/Icon";
import SCSmall  from "../../UI/typography/SC-Small";
import { SCFooter, CopyRight, Line, PhoneNumber } from "./SC-Footer";

// React assets
import SCUl from "../../UI/SC-Ul";
import Button from "../../UI/Buttons/Button";
import linkedinLogo from "../../../assets/images/linkedin-logo.svg";
import phoneIcon from "../../../assets/icons/phone-icon.svg";
import arrowRight from "../../../assets/icons/arrow-right.svg";
import colors from "../../../assets/styles/variables/colors";

const Footer: FC = () => {
  const { t } = useTranslation();
  const career = routes.find((item) => item.name === CAREER_PAGE);

  return (
    <SCFooter>
      <Container>
        <Row>
          <Col lg={4} md={6}>
            <h3>{t("layout.footer.title.group_1")}</h3>
            <p>{t("layout.footer.content.group_1")}</p>
            <SCUl customDisc>
              {/*<li>*/}
              {/*  <a href={`mailto:${contactData.email}`}>{contactData.email}</a>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <PhoneNumber href={`tel:${contactData.phone_short}`}>*/}
              {/*    <Icon image={phoneIcon} size={24}/>*/}
              {/*    {contactData.phone}*/}
              {/*  </PhoneNumber>*/}
              {/*</li>*/}
            </SCUl>
            <p>{t("layout.footer.content.group_2")}</p>
            <a
                href="https://www.linkedin.com/company/nbm-engineering/"
                target="_blank"
                rel="noopener noreferrer"
            >
              <Icon image={linkedinLogo} alt="Linkedin logo" />
            </a>
          </Col>

          <Col lg={4} md={6}>
            <h3>{t("layout.footer.title.group_3")}</h3>
            <SCUl
              none
              dangerouslySetInnerHTML={{
                __html: t("layout.footer.content.group_3"),
              }}
            />
            <SCUl customDisc>
              {/*<li>*/}
              {/*  <a href={`mailto:${contactData.email}`}>{contactData.email}</a>*/}
              {/*</li>*/}
              <li>
                <PhoneNumber href={`tel:${contactData.phone_short}`}>
                  <Icon image={phoneIcon} size={24} alt="Phone icon"/>
                  {contactData.phone}
                </PhoneNumber>
              </li>
            </SCUl>

          </Col>
          <Col lg={4} md={12}>
            <h3>{t("layout.footer.title.group_4")}</h3>
            <p>{t("layout.footer.content.group_4")}</p>
            <br />
            <Button to={career.path} icon={arrowRight}>
              {t("layout.footer.buttons.apply_now")}
            </Button>
          </Col>
        </Row>
      </Container>
      <CopyRight>
        <Line />
        <SCSmall color={colors.footer.text}>Copyright &copy; 2021</SCSmall>
      </CopyRight>
    </SCFooter>
  );
};

export default Footer;
