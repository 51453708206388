export default {
    primary: '#152C53',
    primary_dark: '#0E1E35',
    primary_light: '#5C7CA1',
    secondary: '#F15A29',
    tetriary: "#0B9CD8",
    tetriary_light: "#F6F7F9",
    white: '#FFFFFF',

    header: {
        navigation: '#FFFFFF',
        navigationDark: '#152C53',
        subnavigation: '#FFFFFF',
        subnavigationDark: '#152C53',
    },
    footer: {
        heading: '#FFFFFF',
        icon: '#FFFFFF',
        text: '#FFFFFF',
        link: '#FFFFFF',
    },
    hero: {
        headingLight: '#FFFFFF',
        headingDark: '#152C53',
        subtitleLight: '#FFFFFF',
        subtitleDark: '#152C53',
    },
}