// React requires
import React, {FC, Suspense} from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {I18nextProvider} from "react-i18next";

// React custom configs
import i18n from "./app/config/translate";
import routes from "./routes";

// React libraries
import {Normalize} from "styled-normalize";

// React components
import Meta from "./components/functional/Meta";
import ScrollToTop from "./components/functional/ScrollToTop";
import Header from "./components/layout/header/Header";
import Footer from "./components/layout/footer/Footer";
import Loader from "./components/UI/Loader";

import {AnimatePresence} from 'framer-motion';

const App: FC = (props: any) => {
    return (
        <Suspense fallback={<Loader/>}>
            <Normalize/>
            <Router>
                <I18nextProvider i18n={i18n}>
                    <ScrollToTop>
                        <Meta>
                            <Header/>
                            <main>
                                <AnimatePresence exitBeforeEnter>
                                    <Switch>
                                        {routes.map((route, index) => (
                                            <Route
                                                key={index}
                                                path={route.path}
                                                exact={route.exact}
                                                component={route.component}
                                            />
                                        ))}
                                    </Switch>
                                </AnimatePresence>
                            </main>
                            <Footer/>
                        </Meta>
                    </ScrollToTop>
                </I18nextProvider>
            </Router>
        </Suspense>
    );
};

export default App;
